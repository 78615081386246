<template>
  <div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isAppInstalled: false
    };
  },
  methods: {
    detectAndHandle() {
      // 模拟区分安卓和 iOS 检测
      const userAgent = navigator.userAgent;
      const isAndroid = /Android/i.test(userAgent);
      const isIOS = /iPhone|iPad|iPod/i.test(userAgent);

      // 这里模拟检测 APP 是否安装（根据实际情况替换）
      const detectedInstalled = true; 
      this.isAppInstalled = detectedInstalled;

      if (detectedInstalled) {
        if (isAndroid) {
          // 安卓唤醒逻辑
          window.location.href = 'whistle-android-specific-protocol://'; 
        } else if (isIOS) {
          // iOS 唤醒逻辑
          window.location.href = 'whistle-ios-specific-protocol://'; 
        }
      } else {
        // 未安装则跳转下载页面
        window.location.href = 'your-download-page-url'; 
      }
    }
  },
  created() {
    this.detectAndHandle();
  }
};
</script>

<style scoped>
/* 样式 */
</style>